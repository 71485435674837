import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Grid, Item, Row } from '../components/flexGrid'
import Imgage from '../components/image'
import Embedd from '../components/embedd'
import ImageLinkList from '../components/imageLinkList'

const Link = styled.a`
  font-style: italic;
  margin-bottom: 0.5em;
  text-transform: uppercase;
`
const Content = styled.p`
  margin-bottom: 2em;
`

const ReleasesWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  margin-top: 1em;
`

const Members = styled.div`
  flex-direction: column;
  margin-bottom: 2em;
  display: flex;
`

const ProjectTemplate = ({ pageContext, data }) => {
  const { uri, content, members, links, embedd, title, image } = pageContext

  const { releases } = data
  const filteredReleases = releases.nodes.filter((rel) => rel.artistUri === uri)
  return (
    <Grid>
      <Row>
        <Item w={5}>
          <h1>{title}</h1>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
          <Members>
            {members.map((member, idx) => (
              <span key={idx} dangerouslySetInnerHTML={{ __html: member }} />
            ))}
          </Members>
          {links.map((link, idx) => (
            <Link key={idx} href={link.to}>
              {link.text}
            </Link>
          ))}
        </Item>

        <Item w={7}>
          <Imgage src={image} />

          <ReleasesWrapper>
            {!!filteredReleases.length && (
              <ImageLinkList
                items={filteredReleases.sort(
                  (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)
                )}
                uriPrefix="de/releases"
              />
            )}
            {!filteredReleases.length && embedd && <Embedd src={embedd} />}
          </ReleasesWrapper>
        </Item>
      </Row>
    </Grid>
  )
}

export const pageQuery = graphql`
  fragment ReleaseDetails on ReleasesJson {
    releaseDate
    artistUri
    image
    uri
  }

  query ProjectPageQuery {
    releases: allReleasesJson {
      nodes {
        ...ReleaseDetails
      }
    }
  }
`

ProjectTemplate.propTypes = {
  pageContext: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProjectTemplate
