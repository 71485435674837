import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import ImageLinkItem from './imageLinkItem'

const ImageLinkList = ({ items, uriPrefix, onMouseEnter, onMouseOut }) => {
  const unique = items.reduce((acc, curr) => {
    if (acc.findIndex(({ uri }) => curr.uri === uri) === -1) {
      return [...acc, curr]
    }
    return acc
  }, [])

  return (
    <React.Fragment>
      {unique.map((item) => (
        <Link
          key={item.uri}
          to={uriPrefix ? `/${uriPrefix}/${item.uri}` : item.uri}
          style={{ height: '200px', margin: '0 0 4px 4px' }}
          onMouseEnter={() => onMouseEnter(item.uri)}
          onMouseOut={onMouseOut}
          onBlur={onMouseOut}
        >
          <ImageLinkItem key={item.image} src={item.image} />
        </Link>
      ))}
    </React.Fragment>
  )
}

ImageLinkList.propTypes = {
  uriPrefix: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
  onMouseOut: PropTypes.func,
  onMouseEnter: PropTypes.func,
}

ImageLinkList.defaultProps = {
  items: [],
  onMouseOut: () => undefined,
  onMouseEnter: () => undefined,
}

export default ImageLinkList
