import React from 'react'
import styled from 'styled-components'

import Image from './image'

const GrayScaleTransitionImage = styled(Image)`
  height: 200px;
  width: 200px;
  filter: grayscale(100%);
  transition: filter 0.5s ease-out;
  &:hover {
    filter: grayscale(0);
  }
`

const ImageLinkItem = (props) => <GrayScaleTransitionImage {...props} />

export default ImageLinkItem
